<template>
  <formulario
    :id-formulario="3"
    :id-usuario="idUsuario"
  />
</template>

<script>
import formulario from '@/views/administrador/gestion-formulario/formulario/Formulario.vue'

export default {
  components: {
    formulario,
  },
  data() {
    return {
      idUsuario: 0,
    }
  },
  created() {
    if (localStorage.getItem('userData') !== null) {
      this.idUsuario = JSON.parse(localStorage.getItem('userData')).id
    }
  },

}
</script>

<style>

</style>
